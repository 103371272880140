<template>
  <div class="page-view home">
    <div class="container">
      <div class="flex-wrapper">
        <div class="item">
          <h1 class="page-title"><v-icon name="bi-activity"/>Dashboard</h1>
          <h3 class="subtitle">Inserisci un numero di telefono per vederne le operazioni effettuate.</h3>
          <div class="form-wrapper">
            <Form @submit="searchLogs">
              <label for="phone">
                Ricerca per <b>numero</b>
              </label>
              <Field
                type="tel"
                name="phone"
                rules="phone"
                placeholder="+39"
              />
              <ErrorMessage name="phone" />
              <label for="desc">
                Ricerca per <b>descrizione</b>
              </label>
              <Field
                type="text"
                name="desc"
                placeholder="Descrizione"
              />
              <ErrorMessage name="desc" />
              <label for="desc">
                Ricerca per <b>bucket</b>
              </label>
              <Field
                type="text"
                name="bucket"
                placeholder="Bucket"
              />
              <ErrorMessage name="bucket" />
              <button type="submit" class="btn submit">
                Avvia ricerca
              </button>
            </Form>
          </div>
          <section class="stats" v-if="stats">
            <ul>
              <li
                v-for="(stat, index) in stats"
                :key="index"
              >
                {{stat.label}}&nbsp;:&nbsp;
                <b :class="{'changing' : this.animatedList.includes(stat.label)}">
                  {{stat.val}}
                </b>
              </li>
            </ul>
          </section>
          <Form @submit="sendMail" class="download-form">
            <Field as="select" name="exportType" rules="req" v-model="whatToExport">
              <option value="all">Esporta tutte le partecipazioni</option>
              <option value="win">Esporta solo le partecipazioni vincenti</option>
              <option value="lose">Esporta solo le partecipazioni NON vincenti</option>
            </Field>
            <Field type="mail" name="mail" rules="req|customEmail" placeholder="Indirizzo e-mail" />
            <ErrorMessage name="mail" />
            <button type="submit" class="download">
              <span>ESPORTA</span> il registro delle partecipazioni: inserisci un indirizzo mail valido.
            </button>
          </Form>
          <Form @submit="sendSMS" class="download-form">
            <Field type="tel" name="tel" rules="req|phoneNumber" placeholder="N° telefono (con prefisso)" />
            <ErrorMessage name="tel" />
            <Field type="text" name="body" rules="req" placeholder="Contenuto del messaggio" />
            <ErrorMessage name="body" />
            <button type="submit" class="download">
              <span>INVIA</span> un SMS: inserisci un numero di telefono valido e il corpo del messaggio.
            </button>
          </Form>
        </div>
        <div class="item large">
          <div v-if="validated">
            <h5 v-if="!phoneVal">—Registro completo:</h5>
            <h5 v-else>—Risultati per il numero: {{ phoneVal }}</h5>
            <ul
              :class="{filtered : filteredCats.length > 0}"
              class="categories"
              v-if="categories.length > 0 && !loading"
            >
              <li
                v-for="(cat, index) in categories"
                :key="index"
                :class="{ active : filteredCats.includes(cat) }"
              >
                <button class="btn" @click="filterByCat(cat)">{{ cat }}</button>
              </li>
            </ul>
            <div v-if="loading" class="loading animate-pulse">CARICAMENTO ...</div>
            <h6 class="error" v-else-if="history === false">
              Errore durante la ricerca.
            </h6>
            <div v-else-if="history">
              <div class="table-wrapper">
                <table-lite
                  :is-static-mode="false"
                  :is-loading="table.isLoading"
                  :columns="table.columns"
                  :rows="table.rows"
                  :total="table.totalRecordCount"
                  :sortable="table.sortable"
                  :messages="table.messages"
                  :is-hide-paging="true"
                  @do-search="doSearch"
                  @is-finished="tableLoadingFinish"
                  @get-now-page="currentPage"
                />
                <div class="custom-pagination">
                  <button
                    v-if="tablePaging && tablePaging >= 1"
                    class="prev"
                    @click="getPrevList">
                    « Prev
                  </button>
                  <button
                    v-if="history && history.length"
                    class="next"
                    @click="getNextList">
                    Next »
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img v-if="!history" class="bg-image" src="@/assets/img/dashboard.svg" />
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout
      <b>{{stateMail}}</b>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import toISOString from '@/utils/toISOString.js'

import TableLite from 'vue3-table-lite'
import { reactive } from 'vue'

export default {
  name: 'Home',
  components: {
    Field,
    Form,
    ErrorMessage,
    TableLite
  },
  data () {
    return {
      interval: null,
      loading: false,
      history: null,
      phoneVal: null,
      descVal: null,
      mailVal: null,
      stats: null,
      whatToExport: 'all',
      categories: [
        'in',
        'user_create',
        'item_created',
        'partecipation_created_lost',
        'partecipation_created_win',
        'sent',
        'delivered',
        'blocked_max_part',
        'error'
      ],
      filteredCats: [],
      testQuery: this.$route.query.test,
      validated: false,
      validated2: false,
      validated3: false,
      animatedList: [],
      tablePaging: 0
    }
  },
  computed: {
    ...mapGetters([
      'stateMail'
    ])
  },
  methods: {
    toISOString,
    getPrevList () {
      this.tablePaging = this.tablePaging - 1
      console.log('PAGINATION', this.tablePaging)
      this.doSearch(this.tablePaging * 10, (this.tablePaging * 10) + 10)
    },
    getNextList () {
      this.tablePaging = this.tablePaging + 1
      console.log('PAGINATION', this.tablePaging)
      this.doSearch(this.tablePaging * 10, (this.tablePaging * 10) + 10)
    },
    tableLoadingFinish (elements) {
      this.table.isLoading = false
    },
    currentPage (page) {
      // this.tablePaging = page
    },
    async doSearch (offset, limit) {
      this.table.isLoading = true
      const data = { bucket: this.bucketVal, phone: this.phoneVal, desc: this.descVal, offset: offset, limit: limit, categories: this.filteredCats }
      await this.$store.dispatch('getHistory', data)
        .then((response) => {
          // refresh table rows
          console.log('RSP', response)
          this.history = response
          this.table.rows = this.history
          this.table.totalRecordCount = response.length
        })
      this.table.isLoading = false
    },
    async filterByCat (cat) {
      this.table.isLoading = true
      if (this.filteredCats.includes(cat)) {
        this.filteredCats = this.filteredCats.filter((val, index) => {
          return val !== cat
        })
      } else {
        this.filteredCats.push(cat)
      }
      const data = { phone: this.phoneVal, desc: this.descVal, offset: 0, limit: 10, categories: this.filteredCats }
      await this.$store.dispatch('getHistory', data)
        .then((response) => {
          // refresh table rows
          console.log('RSP', response)
          this.history = response
          this.table.rows = this.history
          this.table.totalRecordCount = response.length
        })
      this.table.isLoading = false
    },
    async getStats (animated) {
      this.animatedList = []
      if (!animated) this.loading = true
      await this.$store.dispatch('getStats')
        .then((rsp) => {
          const temp = []
          for (let i = 0; i < Object.keys(rsp).length; i++) {
            const lbl = Object.keys(rsp)[i]
            const val = Object.values(rsp)[i]
            const translated = {
              label: null,
              val
            }
            switch (lbl) {
              case 'users_count':
                translated.label = 'Totale utenti'
                break
              case 'users_count_active':
                translated.label = 'Totale utenti attivi'
                break
              case 'user_reward_assignments_count':
                translated.label = 'Totale premi assegnati'
                break
              case 'items_count':
                translated.label = 'Totale SMS validi'
                break
              case 'received_sms_count':
                translated.label = 'SMS ricevuti'
                break
              case 'sms_sent':
                translated.label = 'SMS inviati'
                break
              case 'partecipation_count':
                translated.label = 'Totale partecipazioni'
                break
              case 'received_whatsapp_count':
                translated.label = 'Whatsapp ricevuti'
                break
              case 'whatsapp_sent':
                translated.label = 'Whatsapp inviati'
                break
              case 'whatsapp_error_messagges_sent':
                translated.label = 'Whatsapp ERRORE inviati'
                break
              case 'sms_error_messagges_sent':
                translated.label = 'SMS ERRORE inviati'
                break
              default:
                translated.label = lbl
            }
            if (translated.label !== 'Totale SMS validi') temp.push(translated)
            temp.sort(function (a, b) {
              return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0
            })
          }
          if (animated) {
            for (let i = 0; i < temp.length; i++) {
              if (!this.stats || !this.stats[i] || this.stats[i].val !== temp[i].val) {
                this.animatedList.push(temp[i].label)
              }
            }
            // console.log('TO ANIMATE', this.animatedList)
          }
          this.stats = temp
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
        .catch((e) => {
          console.error('GOING TO LOGOUT', e)
          this.$router.push('/login')
        })
      if (!this.stats) this.$router.push('/login')
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    },
    async searchLogs (values) {
      this.validated = true
      this.phoneVal = values.phone
      this.descVal = values.desc
      this.bucketVal = values.bucket
      this.loading = true
      await this.doSearch(0, 10)
      // for (let i = 0; i < this.table.rows.length; i++) {
      //   if (!this.categories.includes(this.table.rows[i].category)) {
      //     this.categories.push(this.table.rows[i].category)
      //   }
      // }

      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
    async sendMail (values) {
      this.validated2 = true
      this.mailVal = values.mail
      const start = process.env.VUE_APP_PRECAMPAIGN_START
      const end = this.toISOString(new Date()) // process.env.VUE_APP_SITE_END
      const payload = {
        start,
        end,
        recipients: [
          this.mailVal
        ]
      }

      let mailFunc = 'sendMail'
      switch (this.whatToExport) {
        case 'all':
          mailFunc = 'sendMail'
          break
        case 'win':
          mailFunc = 'sendMailWinners'
          break
        case 'lose':
          mailFunc = 'sendMailNotWinners'
          break
      }

      await this.$store.dispatch(mailFunc, payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'Email inviata correttamente all\'indirizzo: ' + this.mailVal
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    },
    async sendSMS (values) {
      this.validated3 = true
      const body = values.body
      const to = values.tel
      const payload = {
        body,
        to
      }
      await this.$store.dispatch('sendSMS', payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'SMS inviato correttamente al numero: ' + values.tel
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    }
  },
  setup () {
    const table = reactive({
      isLoading: false,
      // isReSearch: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '3%',
          isKey: true
          // sortable: true,
        },
        {
          label: 'Bucket',
          field: 'bucket',
          width: '3%'
        },
        {
          label: 'Categoria',
          field: 'category',
          width: '5%'
          // sortable: true
        },
        {
          label: 'Dati aggiuntivi',
          field: 'extra_data',
          width: '15%'
        },
        {
          label: 'Descrizione',
          field: 'description',
          width: '30%'
        },
        {
          label: 'Data',
          field: 'created_at',
          width: '10%'
          // sortable: true
        }
      ],
      rows: [],
      totalRecordCount: 0,
      // sortable: {
      //   order: 'id',
      //   sort: 'desc'
      // },
      messages: {
        pagingInfo: 'Elementi {0}-{1} di {2}',
        pageSizeChangeLabel: 'Righe per vista:',
        gotoPageLabel: 'Vai alla pagina:',
        noDataAvailable: 'Nessun dato disponibile.'
      }
    })
    return {
      table
    }
  },
  watch: {
    $route (to, from) {
      clearInterval(this.interval)
    }
  },
  mounted () {
    // console.log('---STORE', this.$store.state)
    this.getStats(false)
    this.interval = setInterval(() => {
      // console.log('check...')
      this.getStats(true)
        .then(async () => {
          if (this.animatedList.length) await this.searchLogs()
        })
      setTimeout(() => {
        this.animatedList = []
      }, 15000)
    }, 60000) // (60sec)
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
</style>
